import './SearchBar.css';
import { useState } from 'react';

function SearchBar({ onSubmit }) {
  const [term, setTerm] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSubmit(term);
  };

  const handleChange = (event) => {
    setTerm(event.target.value);
  };

  return (
    <div className="search-bar">
      <form onSubmit={handleFormSubmit} className="search-form">
        <label className="search-label">Type in your search query for images</label>
        <div className="input-container">
          <input
            value={term}
            onChange={handleChange}
            placeholder="Search images..."
            className="search-input"
          />
          <button
            type="submit"
            style={{
              backgroundColor: 'blue',
              color: 'white',
              borderRadius: '5px',
              padding: '10px 20px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Search
          </button>
        </div>
        <p className="hint-text">Hint: Try searching for 'nature', 'city', 'animals', etc.</p>
      </form>
    </div>
  );
}

export default SearchBar;