import React, { useState } from 'react';

function ImageShow({ image }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = () => {
    fetch(image.urls.full)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'shivam.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => console.error('Error downloading image:', error));
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: image.alt_description,
          url: image.urls.full,
        });
        console.log('Shared successfully!');
      } else {
        throw new Error('Web Share API not supported.');
      }
    } catch (error) {
      // Fallback to a different sharing method
      console.error('Error sharing:', error);
      console.log('Sharing image:', image.urls.full);
    }
  };

  return (
    <div className="image-container">
      <div className="image-frame">
        <img
          src={image.urls.small}
          alt={image.alt_description}
          className={`image ${isHovered ? 'enlarged' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </div>
      <div className="image-details">
        <h3 className="image-title">{image.alt_description}</h3>
        <p className="image-author">By: {image.user.name}</p>
        <div className="image-actions">
          <button className="action-button download-button" onClick={handleDownload}>
            <i className="fas fa-download"></i> Download
          </button>
          <button className="action-button share-button" onClick={handleShare}>
            <i className="fas fa-share-alt"></i> Share
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageShow;
